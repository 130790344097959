.user-profile1 {
  text-align: center;
  margin-top: 50px;
}

p {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  /* animation: typing 2s steps(40, end); */
  overflow: hidden;
  white-space: nowrap;
  border-right: 1px solid black;
}

@keyframes typing {
  from {
    width: 0;
  }
}

