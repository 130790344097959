.card {
  border: 0px solid #000;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 5% auto 2% 10vw;
  padding: 2%;
  max-width: 500px;
  width: 90%;
  border-radius: 5px;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.cardContent p {
  margin-top: 3vh;
  margin-bottom: 1vh;
  font-size: 18px;
}

.socialIcons ul {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.socialIcons ul > li:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.listIcons {
  margin: auto 15%;
  width: 70%;
}

.copyLink{
  color: #1369FF;
}

.facebookIcon {
  color: blue;
}

.twitterIcon {
  color: #1DA1F2;
}

.linkedinIcon {
  color: #2867B2;
}

.whatsappIcon {
  color: #25D366;
}

@media screen and (max-width: 768px) {
  .card {
    margin: 5% auto 2% auto;
    width: 80%;
  }
  
  .listIcons {
    margin: auto;
    width: 70%;
  }
}
