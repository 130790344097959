/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif
}

.faq-section {
    font-size: 18px;
    background-image: url("../../../../public/img/affiliate coverfaqs.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2vw;
}

.wrapper {
    max-width: 760px;
    margin: 50px auto;
    padding: 40px 20px
}

.wrapper .accordion-collapse ul {
    font-size: 14px !important;
    list-style-type: disc !important;
  }

.wrapper .search {
    border: 1px solid #c8c8c8;
    overflow: hidden;
    border-radius: 25px;
    padding: 0 10px;
    margin: 15px 0 20px;
    transition: all 0.3s
}

.wrapper .search:hover,
.wrapper .search:focus-within {
    border: 1px solid transparent;
    box-shadow: 2px 5px 8px #1f1f1f10, 0px -4px 5px #1f1f1f10
}

.wrapper .search .form-control {
    box-shadow: none;
    outline: none;
    border: none
}

.wrapper .search .form-control:focus::placeholder {
    opacity: 0
}

.wrapper .accordion-button {
    font-size: 16px !important;
    font-weight: 500
}

.wrapper .accordion-button:hover {
    background-color: #f8f8f8
}

.wrapper .accordion-button:focus {
    box-shadow: none
}

.wrapper .accordion-button::after {
    background-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #c8c8c8; */
    background-position: center center;
    border-radius: 50%
}

.wrapper .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #f7f7f7;
    font-weight: 600;
    border-bottom: 1px solid #ddd !important
}

.accordion-button:not(.collapsed)::after {
    border-color: #1E88E5
}

.wrapper .accordion-button.collapsed {
    border-bottom: 1px solid #ddd !important
}

.wrapper .accordion-collapse.show {
    border-bottom: 1px solid #ddd !important
}

.wrapper .accordion-button:focus {
    color: #1E88E5; /* Change this to your desired color */
}

/* .wrapper .accordion-collapse {
    background-color: #eaf3fa
} */

.wrapper .accordion-collapse ul li {
    line-height: 2rem;
    width: 100%;
    padding: 0.5rem 1.3rem
}

.wrapper .accordion-collapse ul li:hover {
    background-color: #c9e7ff
}

.wrapper .accordion-collapse ul li a {
    text-decoration: none;
    color: #333;
    font-size: 0.85rem;
    font-weight: 400;
    display: block
}


.wrapper .accordion-collapse ul li:hover a {
    color: #222
}

.pad {
    padding: 2vw;
    max-height:15rem;
    overflow-y: scroll;
}

@media (max-width: 777px) {
    .wrapper {
        margin: 50px 20px
    }
}

@media (max-width: 365px) {
    .wrapper {
        margin: 50px 10px
    }

    .w-75 {
        width: 90% !important
    }
}

.disc {
    padding-right:10px
}