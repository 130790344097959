.affilate-button {
    display: flex;
    justify-content: center;
    padding: 1% 1% 0 1%;
    background: #126AFF;
    background-size: 800% 400%;
    padding: 2vh 1vw;
    border: none;
    border-radius: 28px;
    font-size: 18px;
    font-weight: 700;
    color: white;
    overflow: hidden;
    white-space: nowrap;
}

.td {
    text-decoration: none !important;
}

.td :hover {
    background: #92C5FD
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}