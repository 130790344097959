section {
    font-size: 18px;
    background-image: url("../../../../public/img/affiliate1-1.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.infoPage {
    background-color: #000000;
    opacity: 0.99;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    color: hsl(0, 0%, 100%);
    /* text-align: center; */
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 2rem;
    font-size: 1rem;
    width: 100%;
    /* padding: 6rem 0rem; */
    padding-bottom: 2rem;
    text-align: left !important;
}

.container-cls {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    background-color: black;
    color: white;
    flex-direction: row-reverse;
    padding: 25px;
}

.contentContainer-cls {
    flex: 1 1;
    /* padding: 0 2rem; */
    max-width: 40vw;
    padding-left: 4rem;
    padding-right: 1rem;
}

.imageContainer-cls {
    flex: 1;
    text-align: right;
    padding: 4rem;
    /* padding-top: 6.5rem; */
    /* padding-left: 1.5rem; */
    /* Aligning the image to the right */
}

.infoPage h2 {
    opacity: 1;
    display: flex;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.4em;
    /* margin: 2rem; */
    margin-top: 1em;
}

.head-text {
    color: #FFFF;
    padding-top: 3rem;
    font-size: 45px;
    font-weight: 700;
    line-height: 80px;
}

.desc {
    opacity: 1;
    text-align: left;
    /* margin: 2rem; */
    margin-top: 0.25em;
    color: #FFFFFFE0;
    font-size: 1rem;
    font-weight: 300 !important;
    letter-spacing: 0.03rem;
    list-style: 2rem;
    line-height: 2rem;
}

.infoPage .button3 {
    opacity: 1;
    margin: 1.5rem auto 0.5rem auto;
    padding: 1.5rem 2rem 0.5rem 2rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
}

.login-at-end {
    opacity: 1;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.05rem;
    margin-top: 0.5em;
    margin-bottom: auto;
}

.login-at-end a {
    color: rgb(17, 86, 209);
    text-decoration: none;
}

button {
    opacity: 1 !important;
}

@media only screen and (max-width: 600px) {
    .infoPage h2 {
        margin-top: 0.5em;
    }
}


@media only screen and (max-width: 767px) {

    .infoPage {
        font-size: 0.875rem;
        padding: 10rem 4rem;
    }

    .infoPage h2 {
        font-size: 3rem;
        margin: 1.5rem;
    }

    .infoPage h5 {
        font-size: 1.2rem;
        margin: 1.5rem;
    }

    .infoPage .button3 {
        padding: 1rem 2rem 0.5rem 2rem;
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .infoPage {
        font-size: 0.875rem;
        padding: 8rem 8rem;
    }

    .infoPage h2 {
        font-size: 3.5rem;
        margin: 1.5rem;
    }

    .infoPage h5 {
        font-size: 1.3rem;
        margin: 1.5rem;
    }

    .infoPage .button3 {
        padding: 1.5rem 2rem 0.5rem 2rem;
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .infoPage {
        font-size: 1rem;
        padding: 8rem 8rem;
    }

    .infoPage h2 {
        font-size: 4rem;
        margin: 1.5rem;
    }
}

.infoPage h5 {
    text-align: left;
    /* margin: 2vw; */
    margin-top: 0.25em;
    color: #FFFFFFE0;
    font-size: 1.5em;
    font-weight: 100;
    letter-spacing: 0.3px;
}

@media only screen and (max-width: 600px) {
    .infoPage h2 {
        margin-top: 0.5em;
    }

    .infoPage h5 {
        margin-top: 0.1em;
    }
}

@media only screen and (max-width: 767px) {
    .infoPage {
        font-size: 14px;
        padding: 1rem 2rem;
    }

    .infoPage h2 {
        font-size: 3em;
        margin: 1.5vw;
    }

    .infoPage h5 {
        font-size: 1.2em;
        margin: 1.5vw;
        margin-top: 0.2em;
    }

    .infoPage .button3 {
        padding: 10px 20px 2px 20px;
        margin-bottom: 2vh;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .infoPage {
        font-size: 14px;
        padding: 2rem 2rem;
    }

    .infoPage h2 {
        font-size: 3.5em;
        margin: 1.5vw;
    }

    .infoPage h5 {
        font-size: 1.3em;
        margin: 1.5vw;
        margin-top: 0.3em;
    }

    .infoPage .button3 {
        padding: 15px 20px 2px 20px;
        margin-bottom: 2vh;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .infoPage {
        font-size: 16px;
        padding: 2rem 2rem;
    }

    .infoPage h2 {
        font-size: 4em;
        margin: 1.5vw;
    }

    .infoPage h5 {
        font-size: 1.5em;
        margin: 1.5vw;
        margin-top: 0.4em;
    }

    .infoPage .button3 {
        padding: 20px 20px 2px 20px;
    }
}


@media only screen and (max-width: 600px) {
    .infoPage h5 {
        margin-top: 0.1em;
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 767px) {
    .infoPage h5 {
        font-size: 0.9em;
        margin: 1.5vw;
        margin-top: 0.2em;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .infoPage h5 {
        font-size: 1.0em;
        margin: 1.5vw;
        margin-top: 0.3em;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .infoPage h5 {
        font-size: 1.1em;
        margin: 1.5vw;
        margin-top: 0.3em;
    }
}

@media only screen and (min-width: 1200px) {
    .infoPage h5 {
        font-size: 1.2em;
        margin: 2vw;
        margin-top: 0.25em;
    }
}

/* Media Query for responsive design */
@media (max-width: 768px) {
    .container-cls {
        flex-direction: column;
        flex-direction: column-reverse;
        text-align: center;
        padding: 2%;
    }

    .imageContainer-cls {
        margin-bottom: 1rem;
        text-align: center;
    }

    .contentContainer-cls {
        max-width: 80vw;
    }
}