
.container {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 30px;
  width: 400px;
  justify-content: center;
  border-bottom:4px solid #0062cc 
}

.form h2 {
  margin-bottom: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.formGroup label {
  font-weight: 500;
  margin-bottom: 5px;
}

.formGroup input {
  border-radius: 100px;
  border: 1px solid #ccc;
  padding: 10px;
  padding-left: 24px;
  font-size: 14px;
}

input:focus {
  outline:none;
  border-color: #126AFF;
}
.roundedBtn {
  border-radius: 100px !important;
  width: 100%;
}

.button {
  margin-top: 1rem;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  transition: background-color 0.2s ease-in-out;
  justify-content: center;
}

.button:hover {
  background-color: #0062cc;
}
.rememberForgotContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rememberForgotContainer input[type="checkbox"] {
  margin-right: 0.5rem;
}

.rememberForgotContainer a {
  font-size: 0.8rem;
  color: #999;
  text-decoration: none;
}
.heading{
text-align: center;
}
.googleButton {
/* display: block; */
justify-content: center;
margin-top: 10px;
/* Add more styles as needed */
}


.signuplink {
margin-top: 4vh;
text-align: center;
font-size: 1rem;
}

.signup-link a {
color: #0077cc;
text-decoration: none;
}
.buttonContainer {
display: flex;
justify-content: center;
margin-top: 20px;
}


