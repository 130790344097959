.container {
    background-color: #f2f2f2;
    color: #fafafa;
    background-image: linear-gradient(180deg, #126AFF 0%, #060531 100%);
  }
  
  
  .tagBox {
    /* margin: 4vh 4vw; */
    padding: 4vh 4vw;
  }
  
  .tags {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .tagsHead {
    display: flex;
    flex-direction: column;
  }
  
  .tag1 {
    padding: 1vh 2vw;
    font-size: 34px !important;
  }
  
  .subtag {
    padding: 0vh 2vw;
    font-size: 15px !important;
  }
  
  .buttonAtLayout {
    padding: 15px 26px;
    font-size: 18px;
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    fill: #126AFF;
    color: #126AFF;
    background-color: #FFFFFF;
    border:0;
    outline:none;
    border-radius: 6px;
    /* height: 100%; */
  }
  