.container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.txtMsg {
  font-weight: 500;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card {
  padding: 24px;
  width: 600px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

@media only screen and (max-width: 600px) {
  .card {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
}
