p,
span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

.elementor {

  padding: 15px 15px 15px 15px;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 0px 0px 5px 0px;
  border-color: #126AFF;
  box-shadow: 4px 6px 10px -5px rgba(0, 0, 0, 0.5);
}

.works {
  background-color: hsl(0, 0%, 100%);
}

.worksContainer {
  margin: 6vh 6vw;
}

.heading {
  /* margin-top: 2vh; */
  margin-top:2rem;
  margin-bottom:2rem;
  /* padding-top: 4vh; */
  font-size: 24px;
}

.worksContainer h1 {
  text-align: center;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  
}

.card1,
.card2,
.card3,
.card4 {
  flex: 0 0  calc(25% - 3vw);
  /* Adjust the width based on the number of cards */
  margin: 2vh 1vw;
  background-color: transparent;
  padding: 10px;
  line-height: 1.5;
  padding: 22px 15px 15px 15px;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 0px 0px 5px 0px;
  border-color: #126AFF;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 5px;
}

.card1 img,
.card2 img,
.card3 img {
  width: 100px;
  height: 100px;
}

.card1 h2,
.card2 h2,
.card3 h2,
.card4 h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  margin-top:20px;
}

.card1 div:nth-child(4),
.card2 div:nth-child(4),
.card3 div:nth-child(4),
.card3 div:nth-child(4) {
  font-size: 16px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }
  .card1,
  .card2,
  .card3,
  .card4 {
    flex: 0 0 80%;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    justify-content: space-between;
  }
}

.works {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.worksContainer {
  text-align: center;
  margin-bottom: 20px;
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.grid-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  max-width: 90vw;
  /* overflow-x: scroll; */
}

.grid-item {
  flex: 25% 25% 25% 25%;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 5px;
  margin-right: 20px;
}

.elementor-widget-container {
  margin-bottom: 10px;
}

.elementor-icon-box-title {
  font-size: 18px;
  font-weight: bold;
}

.elementor-icon-box-description {
  font-size: 14px;
}

.elementor-icon {
  font-size: 24px;
  margin-right: 10px;
}
