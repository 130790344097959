.footerBottom {
    color: whitesmoke;
    background-color: black;
    margin: 0;
    padding: 4vh 2vw;
    text-align: center;
}

.footerUser {
    color: rgb(0, 0, 0);
    background-color: rgb(235, 235, 235);
    margin: 0;
    padding: 2vh 0 1.5vh 0;
    text-align: center;
}