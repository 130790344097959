.servicesContainer {
  font-family: "Poppins", sans-serif;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32%, 1fr)); /* Responsive columns */
  gap: 1.5rem; /* Gap between grid items */
  padding: 1.56em;
  align-items: center;
  margin-bottom: 2%;
}

.card {
  border: 0px solid #000;
  box-shadow: 0 0 5px #888;
  background-color: white;
  padding: 5%;
  border-radius: 5px;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.cardContent p {
  margin-top: 3vh;
  margin-bottom: 1vh;
  font-size: 14px;
}

.socialIcons ul {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.socialIcons ul > li:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.listIcons {
  margin: auto;
  width: 70%;
}

.copyLink {
  color: #1369FF;;
}

/*  */


/* Clipboard.module.css */

.clipboard {
display: flex;
align-items: center;
justify-content: space-between;
border: 1px solid gray;
padding: 10px;
border-radius: 5px;
margin-bottom: 10px;
}

.text {
font-size: 18px;
margin: 0;
}

.button {
background-color: white;
border: 1px solid gray;
border-radius: 5px;
padding: 5px 10px;
font-size: 18px;
cursor: pointer;
}

.button:hover {
background-color: gray;
color: white;
}

.ToCopyURL {
font-size: 14px;
}

li{
list-style: none;
}

.facebookIcon {
color: blue;
}

.twitterIcon {
color: #1DA1F2;
}

.linkedinIcon {
color: #2867B2;
}

.whatsappIcon {
color: #25D366;
}

