.clipboard {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  }
  
  .clipboard span {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .clipboard button {
    display: flex;
    /* width: 20%; */
    height: 2rem;
    width: 2rem;
    justify-content: center;
    align-items: center;
  }