.user-profile {
  margin-top: 3rem !important;
  width: 50%;
  margin: 0 auto;
  background-color: #f2f2f7;
  border-radius: 4px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.custom-phone-input-edit {
  height:40px !important;
  width: 100% !important;
  /* border-radius: 100px !important; */
}
.custom-phone-input-edit:disabled{
  background-color: #f5f8fa !important;
}

.custom-phone-btn-edit {
  height:2.5rem;
  /* border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important; */
}


.card-head {
  border-radius: 4px;
  padding: 32px 32px 0px 32px;
  background: #e5e5ea;
  width: 100%;
}

.page-title {
  font-weight: bold;
  padding-bottom: 8px;
}

.panel-content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.text-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text-label {
  font-size: 14px;
}

.MuiOutlinedInput-input {
  padding:12px 14px 14px 14px !important;
}

@media only screen and (max-width: 1024px) {
  .user-profile {
    min-width: 90%;
  }
}
