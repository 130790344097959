.fold-1 {
  height: 60% !important;
  width: 100%;
  background-color: #111827;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  padding: 2rem;
}


.fold-1-content-Container {
  flex: 1;
  padding: 0 2rem;
  max-width: 35%;
}

.top {
  padding-top: 1rem;
  /* background-color: #FFFF; */
}

.heading {
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.fold-1-image-container {
  flex: 1 1;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.cls-img {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
}

.fold-2 {
  height: 30vh !important;
  width: 100%;
  background-color: #111827;
  color: #fff;
  text-align: left;
  justify-content: center;
  padding: 5vh 0;
  /* margin-top: 1rem; */
}

.fold-2-sub-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.fold-2-boxes {
  width: 40%;
  /* min-width: 200px; */
  /* margin: 10px; */
  padding: 20px;
  box-sizing: border-box;
}
.foldtextbox {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0rem;
}

/* Adjust styles for screens with a maximum width of 600px (typical mobile phones) */
@media (max-width: 768px) {
  .fold-1 {
    height: 20vh;
    /* Reduce the height for smaller screens */
    font-size: 16px;
    max-width: 80vw;
    /* Adjust the font size */
  }

  .fold-1-image-container {
    margin-bottom: 1rem;
    text-align: center;
  }

  .fold-1-content-Container {
    max-width: 80vw;
    max-height: 20vh;
  }

  .fold-2 {
    height: 20vh;
    /* Reduce the height for smaller screens */
    font-size: 16px;
    max-width: 80vw;
    /* Adjust the font size */
  }

  .fold-2-new {
    height: 30vh !important;
    width: 100%;
    /* background-color: #ffff; */
    text-align: center;
    justify-content: center;
    padding: 5vh 0;
    background-color: red !important; 
    /* margin-top: 1rem; */
  }

  .fold-2-sub-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .fold-2-boxes {
    width: 40%;
    /* min-width: 200px; */
    /* margin: 10px; */
    padding: 20px;
    box-sizing: border-box;
  }

  .fold-2-boxes {
    width: 45%;
    /* Adjust the width for smaller screens */
  }
}

.footerBottom {
  color: whitesmoke;
  background-color: black;
  margin: 0;
  padding: 4vh 2vw;
  text-align: center;
}
/* New form design css */
.container-main-ref-form {
  color: #fff;
  background-color: #111827;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}

.container-main-left {
  margin-top: 3rem;
  /* padding-left:2rem; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  gap: 1.5rem;
}

.left-p {
  width: 85%;
  margin-top: 1rem;
}

.container-main-right {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.left-margin-ref {
  margin-left: 4rem;
}
.title-h1-ref{
  margin-top:-1.3rem !important;
  margin-bottom: -2rem !important;
}