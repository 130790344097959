.footerHeadContainer{
    color:#fff;
    border-radius: 60px;
    background: linear-gradient(180deg, #126AFF 0%, #060531 100%);
    margin: 4vh 8vw;
    padding: 3.5vh 4vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.button3 {
    text-decoration: none;
}

.affilatebutton {
    text-decoration: none ;
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 2vh 4vw;
    border: none;
    border-radius: 40px;
    font-size: 18px;
    font-weight: 700;
    color: #126AFF;
}

@media (max-width: 768px) {
    .footerHeadContainer {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

