.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
  background-color: #f3f3f3;
  /* margin-top: 200px; */
}

.loading {
  border: 6px solid #bcbaba;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  /* margin-top: 200px; */

  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading p {
  display: flex;
  font-weight: bold;
}

.table-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 15px; */
}

.filter-container {
  display: flex;
  align-items: center;
  gap:1.2rem;
  margin:10px 0;
  background:#f7f7f7;
  padding: 10px 1rem;
  border-radius: 10px;
}

.search {
  padding: 8px 12px;
  border-radius: 5px;
  outline: none;
  border:1px solid #8d8d8d;
}

.email-dropdown{
  display: flex;
  flex-direction: column;
  gap:5px;
}
.email-dropdown p {
  color:#0d6efd;
  margin-right:1rem;
  cursor:pointer;
  border-bottom: 1px solid rgb(175, 175, 175);
  padding:5px 10px;
}

.nodata {
  font-style: italic;
  color: #8d8d8d;
}

.export-button {
  top: 20px;
  right: 30px;
  margin: 10px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}