input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.red-delete-tag {
  font-size: 12px;
  background: #FF6969;
  color: #fff;
  padding: 5px 7px;
  border-radius: 10px;
}


.table-container h2 {
  padding: 10px 0px 30px 0px;
  display: flex;
  justify-content: center;
}

.table_container1 {
  margin-top: 20px;
}

.payment-email {
  max-width: 200px !important;
}

.custom-height {
  height: 210px;
}

.centered-heading {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 10vh;
  /* Adjust this value to set the desired height */
}

.affiliate {
  margin-top: 10px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.table-container th,
.table-container td {
  padding: 8px;
  border: 1px solid #ddd;
}

.table-container th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}

.table-container tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-container tbody tr:hover {
  background-color: #eaf6ff;
}

.table-container td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chart-container {
  width: 40vw;
  height: 35vh;
  margin-bottom: 20px;
}

.chart-container {
  display: flex;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.chart-item {
  border: 1px solid #ccc;
  padding: 20px;
  /* margin-bottom: 10px; Add some margin at the bottom */
}


.table-container {
  position: relative;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.export-button {
  position: absolute;
  top: 20px;
  right: 30px;
  margin: 10px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.export-button:hover {
  background-color: #555;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr th {
  background-color: #126AFF;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #126AFF;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #126AFF;
}

.sort-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.sort-container label {
  font-weight: bold;
}

.sort-container select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.sort-container select:focus {
  outline: none;
  border-color: #526892;
}

.sort-container input[type="text"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.sort-container input[type="text"]:focus {
  outline: none;
  border-color: #526892;
}

.sort-container button {
  padding: 5px 10px;
  background-color: #526892;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sort-container button:hover {
  background-color: #405a77;
}

.table-scroll {
  /* overflow-x: auto; */
  /* overflow-y: auto; */
  max-width: 100%;
  height: 45vh;
}

.styled-table {
  width: 100%;
}

.pagination-handler {
  text-align: right;
  margin-top: 1rem;
}

.chart-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 5rem;
}

.show-ref-p {
  font-weight: 500;
  color: #3C81F6;
  cursor: pointer;
  text-align: center
}

.show-ref-p:hover {
  text-decoration: underline;
}