.container {
  /* margin-top: 3%; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 2rem;
  flex-wrap: wrap;
}

.main {
  width: 100%;
  background-color: #fff !important;
  background-color: red;
}

.payment {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card {
  background-color: #fff;
  margin: 0;
  padding: 2%;
}

.profile-field {
  display: flex;
  gap: 5px;
}

.card > h1 {
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 7px;
  border-bottom: 1px solid #f2f2f2;
}

.profile-field span {
  font-weight: 600;
}

.payment-page-container {
  background-color: blue;
  width: 100%;
  /* margin: 0 auto; */
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.payment-page-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.payment-section {
  margin-bottom: 30px;
  border: 1px solid rgb(183, 183, 183);
  padding: 10px;
  border-radius: 5px;
}

.payment-section-heading {
  font-size: 16px;
  margin-bottom: 10px;
}

/* .payment-info {
    display: flex;
    align-items: center;
    justify-content: center;
  } */

.payment-info-input-container {
  /* background-color: rgb(201, 171, 171); */

  margin: 5px 12px;
}
.payment-page-label {
  display: block;
  text-align: left;
  font-size: 15px;
  margin-bottom: 4px;
}

.payment-page-input {
  padding-left: 15px;
}

.payment-info-input {
  width: 100%;
  padding-left: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.payment-methods {
  display: flex;
  flex-direction: column;
}

.payment-method {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.payment-method input[type='radio'] {
  margin-right: 10px;
}

.payment-method label {
  font-size: 16px;
}

.payment-button-container {
  text-align: center;
  margin-top: 20px;
}

.payment-page-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.payment-page-button:hover {
  background-color: #45a049;
}

.payment-page-heading {
  margin-bottom: 20px;
}

.error-message {
  background-color: #ffe6e6;
  color: #ff0000;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.payment-section {
  margin-bottom: 20px;
  text-align: center;
}

.payment-section-heading {
  margin-bottom: 10px;
}

.payment-page-amount {
  font-size: 24px !important;
  font-weight: bold;
  text-decoration: none;
}

.payment-page-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.payment-methods {
  display: flex;
  flex-direction: column;
}

.payment-method {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.payment-method input[type='radio'] {
  margin-right: 5px;
}

.payment-button-container {
  text-align: right;
}

.payment-page-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.payment-page-heading {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid black;
}

.payment-button-container {
  text-align: center;
  margin-top: 20px;
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}
