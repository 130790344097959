.video-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    /* padding: 20px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    width: fit-content;
}

.close-button {
    position: absolute;
    top: -35px;
    right: -3px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

/* Style the close button to appear as an "X" */
.close-button span {
    font-weight: bold;
    font-size: 30px;
}