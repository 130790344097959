.container {
  margin: 20px;
}

.heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.profileInfo {
  background-color: #e5e5ea;
  padding: 20px 20px 0 20px;
  margin:20px;
  border-radius: 10px;
}

.label {
  font-weight: 500;
}

.table-container {
  margin-top: 220px;
}

.centered-heading {
  display: flex;
  justify-content: center;
  height: 10vh;
}

.affiliate {
  margin-top: 10px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.table-container th,
.table-container td {
  padding: 8px;
  border: 1px solid #ddd;
}

.table-container th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}

.table-container tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-container tbody tr:hover {
  background-color: #eaf6ff;
}

.table-container td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} 

.paymentLink {
  text-decoration: none;
}

.paymentButton {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.paymentButton:hover {
  background-color: #45a049;
}
.totalCommissionContainer {
  padding: 10px;
  text-align: center;
}

.totalCommissionAmount {
  font-weight: bold;
  font-size: 24px;
}
.makePaymentContainer {
  padding: 10px;
  text-align: center;
}

.no-referrals{
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}
