.works {
    background-color: hsl(0, 0%, 100%);
  }
  
  .worksContainer {
    margin: 6vh 6vw;
  }
  
  .heading {
    /* margin-top: 2vh;
    padding-top: 4vh; */
    font-size: 30px;
    font-weight: 700;
  }
  
  .worksContainer h1 {
    text-align: center;
  }
  .centerDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
  }
  
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .card1,
  .card2,
  .card3 {
    flex: 0 0 calc(33.33% - 2vw); /* Adjust the width based on the number of cards */
    margin: 2vh 1vw;
    background-color: transparent;
    padding: 10px;
    line-height: 1.5;
    text-align: center;
  }
  
  .card1 img,
  .card2 img,
  .card3 img {
    width: 100px;
    height: 62px;
  }
  
  .card1 h2,
  .card2 h2,
  .card3 h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card1 div:nth-child(3),
  .card2 div:nth-child(3),
  .card3 div:nth-child(3) {
    font-size: 18px;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .wrapper {
      flex-direction: column;
      align-items: center;
    }
  
    .card1,
    .card2,
    .card3 {
      flex: 0 0 80%;
    }
  }
  
  @media (min-width: 1024px) {
    .wrapper {
      justify-content: space-between;
    }
  }
  