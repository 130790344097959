/* Footer.module.css */

.footer {
    background-color: #fcfcfc;
    padding-top: 1.5rem;
  }
  
  .container {
    margin: 0 auto;
  }
  
  .footerWidgets {
    display: flex;
    flex-wrap: wrap; /* Wrap widgets to new line on smaller screens */
    justify-content: space-evenly;
  }
  
  .footerWidget {
    /* flex: 1; */
    /* margin-right: 20px; */
  }
  
  .mediaImage {
    text-align: center;
  }
  
  .imageFooter {
    width: 200px;
    height: 100px;
  }
  
  .customHTML {
    margin-top: 20px;
  }
  
  .textWidget {
    text-align: center;
  }
  
  .footerBtnLink {
    text-decoration: none;
  }
  
  .footerBtn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .navMenu {
    margin-top: 20px;
  }
  
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.5;
  }
  
  .menuItem {
    font-size: 15px !important;
    margin-bottom: 10px;
  }
  
  .menuItem a {
    color: #333;
    text-decoration: none;
  }
  
  .menuItem a:hover {
    text-decoration: underline;
  }
  
  .footerBottom {
    color: whitesmoke;
    background-color: black;
    margin: 0;
    padding: 4vh 2vw;
    text-align: center;
  }
  
  .socialUl {
    padding:0px;
    display: flex;
    gap:16px;
  }

  .icon{
    height: 24px;
    width: 24px;
  }

  /* Responsive Styles */
  
  @media only screen and (max-width: 768px) {
    /* Adjust footer widget layout for smaller screens */
    .footerWidgets {
      flex-direction: column;
      justify-content: center;
    }
  
    .footerWidget {
      margin-bottom: 20px;
    }
  }
  