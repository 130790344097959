.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* width: 100%; */
    /* height: 100%; */
    min-height: 100vh;
    background-color: #f3f3f3;
    /* margin-top: 200px; */
  }
  
  .loading {
    border: 6px solid #bcbaba;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    /* margin-top: 200px; */
    
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading p {
    display: flex;
    font-weight: bold;
  }