.container{
  min-height: 90vh;
  margin: 2vh 1vw;
  padding: 1vh auto;
}
.container>{
  margin: 2vh 1vw;
  padding: 1vh auto;
}
.heading{

  text-align: center;
  border: 0;
  outline: 0;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  padding: 2vh;
  margin: 6vh;
  color: #111827;
  font-family: "Poppins", Sans-serif;
  font-size: 42px;
  font-weight: 600;
  line-height: 50px;
  -webkit-text-stroke-width: 0px;
  stroke-width: 0px;
  -webkit-text-stroke-color: #000;
  stroke: #000;
}

.link {
  margin: 0 10px;
  font-size: 20px;
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
}

.link:hover {
  color: blue;
}
