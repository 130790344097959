.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
}

.imageContainer {
  flex: 1;
  text-align: right;
  /* Aligning the image to the right */
}

.gif {
  max-width: 100%;
  height: auto;
}

.contentContainer {
  flex: 1;
  padding: 2.2rem 2rem 0.3rem 2rem;
  max-width: 754px
  /* max-width: 50vw; */
}

.heading {
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.list {
  list-style-type: none;
  padding: 2%;
  font-size: 15px;
  font-weight: 200;
  line-height: 2.5;
}

.icon {
  margin-right: 0.5rem;
  vertical-align: middle;
}

/* Media Query for responsive design */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    flex-direction: column-reverse;
    text-align: center;
    padding: 2%;
  }

  .imageContainer {
    margin-bottom: 1rem;
    text-align: center;
  }

  .contentContainer {
    max-width: 80vw;
  }
}