.signupContainer {
  margin-top: 2rem !important;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  border-radius: 30px 25px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-bottom:4px solid #0062cc 

}

.signupForm {
  display: flex;
  flex-direction: column;
}

.heading {
  text-align: center;
}

.custom-phone-input{
  flex:1.15 1;
  height:45px !important;
  width: 100% !important;
  border-radius: 100px !important;
  margin-bottom:20px
}

.custom-phone-btn {
  height:45px;
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.signupTagline {
  text-align: center;
  /* white-space: wrap; */
  min-width: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #333;
}

.signupForm label {
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
  font-size: 14px;
}

.signupForm input {
  margin-bottom: 20px;
  border-radius: 100px;
  border: 1px solid #ccc;
  padding: 10px;
  padding-left: 24px;
  font-size: 14px;
}

.signupForm input:focus {
  outline: none;
  border-color: #0077cc;
}

.signupForm .required:after {
  content: "*";
  color: #ff4d4f;
}

.signupButton {
  border-radius: 100px;
  margin-top: 20px;
  padding: 10px;
  background-color: #126aff;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  border: 0px;
}

.signupButton:hover {
  background-color: #005ea1;
}

.signupLink {
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
  font-size: 14px;
  color: #333;
}

.signupLink a {
  color: #0077cc;
}

/* Media queries for responsive design */
@media only screen and (min-width: 600px) {
  .signupTagline {
    font-size: 12px;
  }

  .signupForm label {
    font-size: 16px;
  }

  .signupForm input {
    font-size: 16px;
  }

  .signupButton {
    font-size: 16px;
  }

  .signupLink {
    font-size: 16px;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 400px;
  justify-content: center;
}

.form h2 {
  margin-bottom: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.formGroup label {
  font-weight: bold;
  margin-bottom: 5px;
}

.formGroup input {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
}

.button {
  margin-top: 1rem;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  transition: background-color 0.2s ease-in-out;
  justify-content: center;
}

.button:hover {
  background-color: #0062cc;
}
.rememberForgotContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rememberForgotContainer input[type="checkbox"] {
  margin-right: 0.5rem;
}

.rememberForgotContainer a {
  font-size: 0.8rem;
  color: #999;
  text-decoration: none;
}
.heading {
  text-align: center;
}
.googleButton {
  /* display: block; */
  justify-content: center;
  margin-top: 10px;
  /* Add more styles as needed */
}

.signuplink {
  margin-top: 4vh;
  text-align: center;
  font-size: 1rem;
}

.signup-link a {
  color: #0077cc;
  text-decoration: none;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.filedRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:0.5rem
}
.halfInput {
  flex:1;
  width: 100%;
} 