.up-container {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 0.5px solid rgb(231, 231, 231);
    margin: 0 1.6rem;
    /* background-color: #e5e5ea; */
}

.btn-user {
    border: 0;
    background-color: rgb(18, 106, 255);
    border: 1px solid rgb(18, 106, 255);
    color: #fff;
    outline: none;
    border-radius: 5px;
    padding: 7px 16px;
    font-size: 18px;
}

.btn-user:hover {
    background-color: #fff;
    color: rgb(18, 106, 255);
    border: 1px solid rgb(18, 106, 255);
    transition: all;
}

.btm-table {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.btm-table p {
    font-weight: 500;
}

.container-block {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 40vh;
}

.page-title {
    margin-top: 1.5rem;
    font-weight: 700;
    display: block;
}

.no-pay {
    color: #6B7280;
    font-weight: 500;
    font-style: italic;
    text-align: center
}

.amount-desc {
    font-weight: 500;
}

.amount {
    font-weight: 700;
    font-size: 22px
}

.table-bottom {
    margin-top: 10px;
    border: 1px solid #6B7280;
    padding: 10px 10px;
    border-radius: 5px;
}

.bottom-lines {
    margin: 5px;
    text-align: center;
    /* display: flex; */
}

.table-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

.btm-table {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-bottom {
    width: 420px;
}

.payout-cards {
    word-wrap: break-word;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .1);
    margin: 0 auto;
    max-width: 100%;
    padding: 20px;
    text-align: center;
    white-space: normal;
    width: 100%;
    height: 30vh;
}