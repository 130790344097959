/* Form CSS */
.fold-3 {
  /* background-color: #E5E5EA; */
  background-color: #111827;
  padding: 3rem;
}

.swal-wide{
  font-size: 13px !important;
  width:600px;
}

.loaded {
  display: block;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
    0 6px 20px 0 rgba(165, 200, 213, 0.41);
  width: 700px;
  margin: 0 auto;
  /* margin-top: 30px; */
}

#form-target {
  padding: 20px 26px 40px 26px;
}

.hs-form {
  width: 100%;
  box-sizing: border-box;
}

.hs-form__row {
  margin-bottom: 18px;
}

.hs-form-heading {
  text-align: center;
  color: #111827;
  padding-top: 30px;
}

.submit-btn-referral-team {
  background-color: #126aff !important;
  border: 2px solid #126aff !important;
}

.hs-form__field-row {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}

.hs-form__field-row__column {
  align-self: flex-start;
  flex: 1 1 100%;
  padding: 0 10px;
  width: 100%;
}

.hs-form__field__label {
  font-size: 13px;
  color: #33475b;
}

.hs-form__field__label {
  font-weight: 500;
  line-height: 20px;
}

.hs-form_theme-sharp .hs-form__actions__submit,
.hs-form_theme-sharp .hs-form__field__input {
  border-radius: 0;
}

.hs-form__field,
.hs-form__field__label {
  font-size: 14px;
  color: #212d3a;
  display: block;
  float: none;
  width: auto;
  font-weight: 500;
  line-height: 20px;
  padding-top: 0;
}

.hs-form__field__input {
  display: inline-block;
  width: 100%;
  /* height: 40px; */
  padding: 9px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #33475b;
  background-color: #f5f8fa;
  border: 1px solid #cbd6e2;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.hs-form__field__error,
.hs-form__field__input,
.hs-form__field__label {
  margin-bottom: 4px;
}

.hs-form-a8ef0371-2b5e-4eac-8fe1-d6dbbe555d0a .hs-form__field__label {
  font-size: 13px;
  color: #33475b;
}

.hs-form__field,
.hs-form__field__label {
  font-size: 14px;
  color: #212d3a;
  display: block;
  float: none;
  width: auto;
  font-weight: 500;
  line-height: 20px;
  padding-top: 0;
}

.hs-form_theme-sharp .hs-form__actions__submit,
.hs-form_theme-sharp .hs-form__field__input {
  border-radius: 0;
}

/* .hs-form__field__input--error {
    border-color: #e51520 !important;
} */

.hs-form__field__label__required,
.hs-form__field__required {
  margin-left: 4px;
  color: #e51520;
}

.hs-form__alerts__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hs-form__actions {
  text-align: left;
}

.hs-form__actions__next,
.hs-form__actions__previous,
.hs-form__actions__submit {
  margin: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: 12px;
  position: relative;
  text-align: center;
  background-color: #ff7a59;
  border: 1px solid #ff7a59;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding: 12px 24px;
}

.hs-form__actions__submit,
.hs-form__actions__next {
  background: #ff0201;
  border-color: #ff0201;
  color: #ffffff;
  line-height: 12px;
  font-size: 12px;
}

.hs-form__add__more {
  background: #126aff;
  border-color: #126aff;
  color: #ffffff;
  line-height: 12px;
  font-size: 12px;
  padding: 10px;
  border: 0;
}

.hs-form__field__description,
.hs-form__field__consent-footer {
  font-size: 11px;
  color: #7c98b6;
  margin-bottom: 6px;
}

.hs-form-radio-pad-l {
  padding-left: 6px;
  color: #111827;
}

@media only screen and (max-width: 800px) {
  .loaded {
    width: 100%;
  }
}

.custom-icon-div {
  margin-left: 10px;
  align-items: center;
  display: flex;
}

.custom-icon {
  font-size: 24px;
  color: #ff0201;
  cursor: pointer;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999999;
  /* Add a semi-transparent background if desired */
}

.custom-phone-input {
  background-color: #f5f8fa !important;
  height: 40px !important;
}

.custom-phone-input:focus {
  outline: none !important;
  border: 1px solid #cbd6e2 !important;
}

.react-tel-input input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #126aff !important;
}
