.container-tc {
  width: 70%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.main-tc {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.title {
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.title2 {
  width: 100%;
  text-align: left;
  font-weight: 600;
}

.para {
  font-weight: 400;
  word-break: avoid;
  white-space: pre-wrap;
}

.para-list {
  font-weight: 400;
  word-break: avoid;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  gap:0.5rem
}

.para-italic {
  font-style:italic;
  font-weight: 400;
  word-break: avoid;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  gap:0.5rem
}

.circle {
  height: 6px !important;
  width: 6px !important;
  border-radius: 100%;
  background-color: black;
}
