*{
  font-family: "Poppins", sans-serif !important; 
  scroll-behavior: smooth;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

main {
  margin-top: 3rem;
}

.btn:disabled {
  cursor: not-allowed;
  pointer-events: all;
}