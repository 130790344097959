.nav {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 0px;
  width: 100%;
  height: 70px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .1);

  z-index: 100;
}

.icon {
  padding-right: 7px;
  padding-left: 5px;
}

.logo {
  margin-right: 20px;
}

.logo img {
  max-width: 225px;
  height: auto;
}

.drawer {
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 20px;
  height: 16px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.drawerLine {
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.active .drawerLine:nth-child(2) {
  opacity: 0;
}

.active .drawerLine:nth-child(1),
.active .drawerLine:nth-child(3) {
  transform: rotate(45deg);
}

.drawer.open .drawerLine:nth-child(1),
.drawer.open .drawerLine:nth-child(3) {
  transform: rotate(-45deg);
}

.showDrawer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.buttons {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.buttons li {
  margin-left: 10px;
  vertical-align: middle;
}

.buttons a {
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.btn {
  background-color: #1369FF;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: auto 2px;
}

.btn1 {
  background-color: #fff;
  border: 2px solid #126AFF;
  color: #444;
  padding: 10px 20px;
  border-radius: 50px;
  color: #1369FF;
}

.btn2 {
  border: none;
  padding: 12px 22px;
}

.text {
  text-decoration: none;
  color: #555;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.logout-btn {
  background-color: skyblue;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.drawer-menu {
  display: none;
}

@media only screen and (max-width: 768px) {
  .logo {
    margin-right: 0;
  }

  .drawer {
    display: flex;
  }

  .drawerLine {
    width: 100%;
    height: 2px;
    background-color: #000;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .active .drawerLine:nth-child(2) {
    opacity: 0;
  }

  .active .drawerLine:nth-child(1),
  .active .drawerLine:nth-child(3) {
    transform: rotate(45deg);
  }

  .drawer.open .drawerLine:nth-child(1),
  .drawer.open .drawerLine:nth-child(3) {
    transform: rotate(-45deg);
  }

  .showDrawer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    background-color: #fff;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    padding: 18px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }

  .buttons.show {
    opacity: 1;
    pointer-events: auto;
  }

  .buttons li {
    margin-left: 0;
    margin-bottom: 10px;
    vertical-align: middle;
  }
}

/* Add this CSS code to your existing Header.module.css file */

.logoutBtn {
  padding: 5px 10px;
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logoutBtn:hover {
  background-color: #cc0000;
}

.text {
  padding: 10px;
  transition: background-color 0.3s ease;
}

.text:hover {
  background-color: #f0f0f0;
}

/* Add this CSS code for the animation effect */
.text:hover {
  animation: pulse 0.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.dropdownContainer {
  position: relative;

}

.dropdownContent {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 180px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 5px 0;
  z-index: 1;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.dropdownContainer:hover .dropdownContent {
  display: block;
}

.dropdownContent a {
  color: #333;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  white-space: nowrap;
}

.dropdownContent a:hover {
  background-color: #f1f1f1;
}

.startReferring {
  background-color: #0d6efd;
  font-size: 16px;
  line-height: 1.2 !important;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0em;
  border-radius: 2.3em;
  padding: .5em 1.5em;
  border-color: transparent;
  color: #fff !important;
  box-shadow: 0 .05em .1em rgba(0, 0, 0, .2);
}

/* .startReferring :hover {
  background-color: #0d6efd;
} */

.startReferring :focus {
  outline: none;
}

.startReferring:hover {
  background-color: #060531;
  color: #000;
  /* Change to the text color you prefer */
  transition: background-color 0.3s, color 0.3s;
  /* Add a smooth transition effect */
}